<template>
	<div class="formulaire__cdcpapier">
		<svg class="logo" fill="#404481" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z"/></svg>
		<h3 class="mt-10">Envoi d'un CDC papier</h3>
		<v-form 
			v-model="opt_form.valide"
			mode="aggressive"
			ref="form"
		>
			<InputSearchCab 
				@change="cab_change"
				class="mt-8"
				require
			>
			</InputSearchCab>
			<v-text-field 
				outlined
				v-model="cab"
				:rules="opt_form.rules.cab"
				class="mt-4"
				v-show="false"
				id="hidden-searchcab"
			></v-text-field>
			<v-select
				outlined
				:items="opt_form.liste_objet"
				v-model="objet"
				label="Objet"
				item-text="label"
				item-value="code"
				:rules="opt_form.rules.objet"
				class="mt-4"
			></v-select>
			<v-textarea
				outlined
				name="input-7-4"
				label="Contenu"
				v-model="content"
				:rules="opt_form.rules.content"
				class="mt-4"
			></v-textarea>
			<InputFileUpload
				colorBack="#3d2f59"
				class="mt-4 mb-10"
				@change="files_changes"
			></InputFileUpload>
			<v-checkbox
				label="Le CDC est signé"
				name="cdcSigned"
				v-model="cdcSigned"
				class="mt-4 mb-10"
			></v-checkbox>
			<v-btn 
				class="mt-0" 
				depressed 
				color="#404481" 
				:dark="opt_form.valide"
				:disabled="!opt_form.valide"
				@click="send"
			>
				Envoyer
			</v-btn>
			<v-btn 
				class="ml-4" 
				depressed 
				@click="$router.go(-1);"
			>
				Retour
			</v-btn>		
		</v-form>
	</div>
</template>

<script>
	import InputSearchCab from '../InputCabSearch.vue'
	import InputFileUpload from '../InputFileUpload.vue'
	import { mapActions, mapMutations } from 'vuex';

	export default {
		name: 'Cdcpapier',
		components : {
			InputSearchCab,
			InputFileUpload
		},
		data: () => ({
			cab: "",
			objet: "",
			content: "",
			files: [],
			cdcSigned: "",
			opt_form: {
				liste_objet : [
					{code : "cdc", label: "CDC"},
					{code : "cdc-compl", label: "Complément de CDC"}
				],
				cab_error : false,
				lazy: false,
				valide: false,
				rules : {
					content : [
						v => v != "" ? true : 'Le message est requis',
						v => v.length > 5 ? true : "Le message est trop court"
					],
					objet : [
						v => v != "" ? true : "L'objet est requis"
					],
					cab : [
						v => v != "" ? true : "Le cab est requis",
					]
				}
			},
		}),
		created: function() {

		},
		methods: {
			...mapActions('formulaires', [
				"sendCdcPapier"
			]),
			...mapMutations('formulaires', [
				"setNotif",
				"setNotifMsg"
			]),
			...mapMutations('global', [
				"sendSuccessNotif"
			]),
			cab_change: function(e) {
				this.cab = e;
			},
			files_changes: function(e) {
				this.files = e;
			},
			send: function() {
				var _this = this;

				_this.opt_form.valide = false;

				this.sendCdcPapier({
					cab: this.cab,
					objet: this.objet,
					content: this.content,
					files: this.files.map(x => {
						return x.file_id
					}),
					cdcSigned: this.cdcSigned
				})
				.then(() => {
					_this.opt_form.valide = true;
					_this.sendSuccessNotif("Opération effectuée")
					_this.$router.push('/');
				})
			}
		},
	};
</script>

<style lang="scss">
	.formulaire__cdcpapier {
		width: 40%;
		border: 4px solid #f1f1f1;
		border-radius: 16px;
		margin-top: 30px;
		padding: 20px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: display;
		padding-bottom: 30px;

		.logo
		{
			width: 50px;
			position: absolute;
			margin-left: auto;
			margin-right: auto;
			top: -25px;
			left:0;
			right: 0;
			z-index: 1000;
		}
	}

	@media only screen and (min-width: 701px) and (max-width: 800px) {
		.formulaire__cdcpapier
		{
			width: 50%;
		}
	}

	@media only screen and (min-width: 420px) and (max-width: 700px) {
		.formulaire__cdcpapier
		{
			width: 80%;
		}
	}

	@media only screen and (min-width: 0px) and (max-width: 419px) {
		.formulaire__cdcpapier
		{
			width: 90%;
		}
	}
</style>