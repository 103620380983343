<template>
	<div>
		<v-autocomplete
			v-model="select"
			:loading="loading"
			:items="items"
			:search-input.sync="search"
			item-text="label"
			item-value="cab_contrat"
			cache-items
			hide-no-data
			hide-details
			label="Rechercher un cab"
			outlined
			:error="required_status"
			@change="changed"
		></v-autocomplete>
		<div style="height: 30px"></div>		
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	export default {
		name: 'InputCabSearch',
		props: ['require'],
		data: () => ({
			select: "",
			loading: false,
			items: [],
			search: "",
			required_status: false
		}),
		mounted: function() {
			this.required_status = (this.require == "undefined") ? false : true
		},
		computed: {
			requireStatus: function() {
				if(this.require == undefined) {
					return false
				}

				return this.select == ""
			},
		},
		methods: {
			...mapActions('formulaires', [
				"searchCabAction",
			]),
			querySelections: function(v) {
				var _this = this;
				this.loading = true;
				this.searchCabAction(v)
				.then(response => {
					_this.items = response.data.map(x => {
						x.label = `${x.cab_contrat} - ${x.nom_enseigne}`
						return x
					});
				})
				.finally(() => {
					_this.loading = false;
				})
			},
			changed: function(e) {
				this.$emit('change', e)
			}
		},
		watch: {
			search(val) {
				val && val !== this.select && this.querySelections(val)
			},
			select(val) {
				this.required_status = (this.require == "undefined" || val != "") ? false : true;
			}
		}
	};
</script>

<style lang="scss" scoped>
	
</style>